<template>
    <section>
        <modal ref="modalCompraIngreso" :titulo="titulo" no-aceptar no-cancelar :cargado-modal="cargandoInforme" :height="60" tamano="modal-xl">
            <div class="row mx-0 align-items-center py-2">
                <div class="col-auto text-general">
                    <span class="f-500">
                        Durante:
                    </span>
                    {{ fecha }}
                </div>
                <el-tooltip class="item" effect="light" placement="bottom">
                    <div slot="content" class="text-center" style="width:80px;">
                        <p>Descargar Tabla</p>
                    </div>
                    <div class="bg-grr-red text-general2 cr-pointer shadow ml-2 d-middle-center br-10" style="width:44px;height:44px;" @click="antesGenerar">
                        <i class="icon-file-download-outline f-20" />
                    </div>
                </el-tooltip>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <tabla-general
                    :data="tableData"
                    :usar-paginacion="false"
                    :mostrar-buscador="false"
                    class="custom-table-scroll"
                    :alto="altoTabla"
                    style="width: 100%"
                    >
                        <el-table-column class-name="text-center text-general" prop="nombre" :label="tipo == 1 ? 'Vendedor' : 'Cliente'" width="180" />
                        <el-table-column v-if="tipo == 2" class-name="text-center text-general" prop="leechero" label="Vendedor" width="180" />
                        <el-table-column class-name="text-center text-general" prop="cedis" label="CEDIS" width="180" />
                        <el-table-column class-name="text-center text-general" prop="primer_acceso" label="Primer ingreso" width="180" />
                        <el-table-column class-name="text-center text-general" width="80" label="Vía">
                            <template slot-scope="scope">
                                <i class="text-general f-18" :class="scope.row.via_primer_acceso == 1 ? 'icon-web' : scope.row.via_primer_acceso == 2 ? 'icon-android' : scope.row.via_primer_acceso == 3 ? 'icon-ios' : ''" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="primer_pedido" label="Primer Pedido" class-name="text-center text-general" width="180" />
                        <el-table-column label="Vía" class-name="text-center text-general" width="80">
                            <template slot-scope="scope">
                                <i class="text-general f-18" :class="scope.row.via_primer_pedido == 1 ? 'icon-web' : scope.row.via_primer_pedido == 2 ? 'icon-android' : scope.row.via_primer_pedido == 3 ? 'icon-ios' : scope.row.via_primer_pedido == 4 ? 'icon-manual' : ''" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="valor_primer_pedido2" label="Valor" width="150" class-name="text-center text-general" />
                        <el-table-column prop="id_primer_pedido" label="No. Pedido" width="150" class-name="text-center text-general" />
                        <el-table-column prop="total_pedidos" label="Total Pedidos" width="150" class-name="text-center text-general" />
                        <el-table-column prop="ultimo_acceso" width="150" class-name="text-center text-general" label="Último acceso" />
                        <el-table-column label="Vía" class-name="text-center text-general" width="80">
                            <template slot-scope="scope">
                                <i class="text-general f-18" :class="scope.row.via_ultimo_acceso == 1 ? 'icon-web' : scope.row.via_ultimo_acceso == 2 ? 'icon-android' : scope.row.via_ultimo_acceso == 3 ? 'icon-ios' : ''" />
                            </template>
                        </el-table-column>
                    </tabla-general>
                </div>
            </div>
        </modal>
        <modal ref="modalConfirmar" titulo="Generar Excel" mensaje="¿Desear descargar el archivo con formato decimales con punto o coma?" no-aceptar>
            <slot>
                <div class="row justify-center py-3">
                    <el-radio v-model="tipo_decimal" :label="true">
                        Decimal con punto '.'
                    </el-radio>
                    <el-radio v-model="tipo_decimal" :label="false">
                        Decimal con coma ','
                    </el-radio>
                </div>
            </slot>
            <template v-slot:excel>
                <export-excel
                :data="productos_excel"
                :fields="json_fields"
                :before-generate="startDownload"
                :before-finish="finishDownload"
                worksheet="inventario"
                :name="`accesos usuarios (${fecha}).xls`"
                >
                    <button
                    v-loading="loading_excel"
                    type="button"
                    class="btn mx-2 btn-aceptar text-white br-10 border-0"
                    >
                        Descargar
                    </button>
                </export-excel>
            </template>
        </modal>
    </section>
</template>

<script>
import Accesos from "~/services/accesos_estadisticas"
import Vue from 'vue'
import excel from 'vue-excel-export'

Vue.use(excel)
export default {
    props: {
        titulo: {
            type: String,
            default: () => ''
        }
    },
    data(){
        return {
            tableData: [],
            cargandoInforme: false,
            tipo: 1,
            fecha: '',
            productos_excel: [],
            loading_excel: false,
            tipo_decimal: true,
            altoTabla: 'calc(100vh - 551px)',
            cargando: false
        }
    },
    computed: {
        json_fields(){
            return {
                'ID': 'id',
                [this.tipo == 1 ? 'Vendedor' : 'Cliente']: 'nombre',
                'Vendedor': 'leechero',
                'CEDIS': 'cedis',
                'Primer acceso': 'primer_acceso',
                // 'Via Primer acceso': 'via_primer_acceso',
                'Vía Primer acceso': {
                    field: 'via_primer_acceso',
                    callback: (value) => {
                        let texto = ''
                        switch (value){
                        case 1:
                            texto = 'Web'
                            break;
                        case 2:
                            texto = 'Android'
                            break;
                        case 3:
                            texto = 'iOS'
                            break;
                        default:
                            texto = ''
                            break;
                        }
                        return texto
                    }
                },
                'Primer pedido': 'primer_pedido',
                'ID Pedido': 'id_primer_pedido',
                // 'Via Primer pedido': 'via_primer_pedido',
                'Vía Primer pedido': {
                    field: 'via_primer_pedido',
                    callback: (value) => {
                        let texto = ''
                        switch (value){
                        case 1:
                            texto = 'Web'
                            break;
                        case 2:
                            texto = 'Android'
                            break;
                        case 3:
                            texto = 'iOS'
                            break;
                        default:
                            texto = ''
                            break;
                        }
                        return texto
                    }
                },
                'Valor Primer pedido': {
                    field: 'valor_primer_pedido',
                    callback: (value) => {
                        return this.$options.filters.currency(value, '$', 2, {
                            decimalSeparator: this.tipo_decimal ? '.' : ',',
                            thousandsSeparator: this.tipo_decimal ? ',' : '.',
                        });
                    }
                },
                'Total Pedidos': 'total_pedidos',
                'Último acceso': 'ultimo_acceso',
                // 'Via Último acceso': 'via_ultimo_acceso',
                'Vía Último acceso': {
                    field: 'via_ultimo_acceso',
                    callback: (value) => {
                        let texto = ''
                        switch (value){
                        case 1:
                            texto = 'Web'
                            break;
                        case 2:
                            texto = 'Android'
                            break;
                        case 3:
                            texto = 'iOS'
                            break;
                        default:
                            texto = 'Web'
                            break;
                        }
                        return texto
                    }
                },
                // 'Rotación cedis': {
                //     field: 'rotacion_mia',
                //     callback: (value) => {
                //         return this.$options.filters.currency(value, '', 2, {
                //             decimalSeparator: this.tipo_decimal ? '.' : ',',
                //         });
                //     }
                // }
            }
        }
    },
    created(){
        this.handleResize();
    },
    methods: {
        toggle(ids, id_moneda, tipo, fecha){
            this.tipo = tipo
            this.fecha = fecha
            this.tablaAccesos(ids, id_moneda, tipo)

            this.$refs.modalCompraIngreso.toggle();
        },
        async tablaAccesos(ids, id_moneda, tipo){
            try {
                let params = {
                    id_moneda,
                    ids,
                    tipo
                }
                this.cargandoInforme = true

                const { data } = await Accesos.tablaAccesos(params)

                data.map(el => {
                    el.valor_primer_pedido2 = this.separadorNumero(el.valor_primer_pedido)
                    el.total_pedidos = this.agregarSeparadoresNumero(el.total_pedidos)
                })
                this.tableData = data

            } catch (error){
                console.log(error)
            } finally {
                this.cargandoInforme = false
            }
        },
        handleResize(){
            let alto = window.innerHeight;
            if(alto <= 680){
                this.altoTabla = 'calc(100vh - 434px)'
            }
        },
        antesGenerar(){
            this.$refs.modalConfirmar.toggle()
        },
        async startDownload(){
            try {

                this.loading_excel = true
                this.productos_excel = this.tableData

            } catch (error){
                console.log(error);
            }
        },
        finishDownload(){
            this.loading_excel = false
            this.$refs.modalConfirmar.toggle()
            this.tipo_decimal = true
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 680px) {
    .custom-table-scroll{
        #cont-scroll{
            height: calc(100vh - 434px) !important;
        }
    }
}
</style>
